@import './material.scss';
@import './tailwind.scss';
@import './ripple.scss';

@import './variables.scss';

@import 'klaro/src/scss/klaro.scss';

@import 'klaro.scss';

@import './core-material.scss';

@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/alert';

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
}

html {
  height: -webkit-fill-available;
}
