:root {
  --sidenav-width: 15.2rem; // Set your default width value
}

// make the snackbar overlay full width
// TODO: this overlay breaks the relative positioning of the other material design elements
//.cdk-overlay-pane {
//  width: 100%;
//}

.mdc-snackbar.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    width: 100%; // default is 100% but in web view make it 70%
    padding: 0; // there is some  padding on right
    --mdc-snackbar-container-color: white;
    --mdc-snackbar-container-shape: 8px;
  }

  .mdc-snackbar__label {
    padding: 0;
    --mdc-snackbar-supporting-text-color: black;
  }
}

/*
  Apply the following CSS only for web browsers since there is no side navigation in mobile.
  In the web version, this adjustment is necessary to maintain center alignment and to make it full width,
  as the presence of the side navigation causes disturbance to the layout.
*/
@media (min-width: 1024px) {
  .full-width-snackbar.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      max-width: 736px;
      margin-left: var(--sidenav-width);
    }
  }
}

@media screen and (min-width: 714px) and (max-width: 1024px) {
  .full-width-snackbar.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    max-width: 736px;
  }
}

@media (max-width: 768px) {
  .mdc-snackbar.mat-mdc-snack-bar-container {
    margin-left: 1rem;
    margin-right: 1.5rem;
  }
}
