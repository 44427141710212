[appripple] {
  position: relative;
  overflow: hidden;

  .ripple {
    position: absolute;
    border-radius: 50%;
    opacity: 1;
    pointer-events: none;
    background-color: rgba(6, 58, 168, 0.25); //default color
    transform: scale(0);

    &.ripple-animate {
      animation: ripple 1s;
    }
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
