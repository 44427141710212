/**
* Source: https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap (as used in the index file)
* As We only support DE and EN at the moment only Latin and Latin extension (for diacritics etc) is used.
* one can also download the font from https://github.com/rsms/inter
* Note: unicode-range is kept from what was provided from fonts.googleapis.com
*/

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/inter/inter-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/inter/inter-bold.woff2') format('woff2');
  unicode-range: U+0000-00FF;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons/material.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

