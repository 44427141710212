@import 'ng-zorro-antd/ng-zorro-antd.css';
@import "variables.scss";

// Font definitions
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/inter/inter-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/inter/inter-bold.woff2') format('woff2');
  unicode-range: U+0000-00FF;
}

// Base styles
html {
  font-size: 16px;
}

:root {
  --color-primary-000: #063aa8;
  --color-primary-100: #0951ed;
  --color-primary-200: #009ce6;
  --color-primary-300: rgba(6, 58, 168, 0.25);
  --color-primary-400: #e8effe;
  --color-secondary-000: #212529;
  --color-base-100: #ffffff;
  --color-base-content: #333333;
  --color-error-base: #F4541D;
  --color-error-hover: #D84111; // #F4541D
  --color-disabled-text: #999999;
  --color-disabled-bg: #f5f5f5;
  --color-disabled-border: #cccccc;
  --color-inverted-brand: #666666;

  /* Global font family and weights */
  --font-family: 'Inter', sans-serif;
  --font-weight-regular: 400;
  --font-weight-bold: 700;

  /* Fluid Typography Scale */
  --text-xs: clamp(0.75rem, 0.7rem + 0.25vw, 0.875rem); // 12px - 14px
  --text-sm: clamp(0.875rem, 0.8rem + 0.375vw, 1rem); // 14px - 16px
  --text-base: 16px;
  --text-lg: clamp(1.125rem, 1rem + 0.625vw, 1.25rem); // 18px - 20px
  --text-xl: clamp(1.25rem, 1.1rem + 0.75vw, 1.5rem); // 20px - 24px

  /* Spacing */
  --space-1: clamp(0.25rem, 0.2rem + 0.25vw, 0.5rem); // 4px - 8px
  --space-2: clamp(0.5rem, 0.4rem + 0.5vw, 0.75rem); // 8px - 12px
  --space-3: clamp(0.75rem, 0.6rem + 0.75vw, 1rem); // 12px - 16px
  --space-4: clamp(1rem, 0.8rem + 1vw, 1.5rem); // 16px - 24px
  --space-5: clamp(1.5rem, 1.2rem + 1.5vw, 2rem); // 24px - 32px
  --space-6: clamp(2rem, 1.6rem + 2vw, 3rem); // 32px - 48px

  /* Containers */
  --container-sm: clamp(20rem, 80vw, 40rem);
  --container-md: clamp(40rem, 85vw, 48rem);
  --container-lg: clamp(48rem, 90vw, 64rem);
  --container-xl: clamp(64rem, 95vw, 80rem);

  /* Ant Design Overrides */
  --ant-font-family: var(--font-family);
  --ant-font-size-base: var(--text-base);
  --ant-primary-color: var(--color-primary-000);
  --ant-primary-color-hover: var(--color-primary-100);
  --ant-primary-color-active: var(--color-primary-200);
  --ant-primary-color-outline: var(--color-primary-300);
  --ant-btn-height-base: 3rem; // 48px
  --ant-btn-padding-horizontal-base: 1.5rem; // 24px
  --ant-btn-font-size-base: 1rem;
  --ant-btn-font-size-sm: 0.875rem; // 14px

  /* Typography Variables */
  --ant-h1-size: 1.75rem; /* 28px */
  --ant-h2-size: 1.5rem;  /* 24px */
  --ant-h3-size: 1.25rem; /* 20px */
  --ant-h4-size: 1.125rem; /* 18px */
  --ant-h5-size: 1rem;    /* 16px */
  --ant-h6-size: 0.875rem; /* 14px */

  --ant-h1-weight: 700;
  --ant-h2-weight: 700;
  --ant-h3-weight: 700;
  --ant-h4-weight: 500;
  --ant-h5-weight: 500;
  --ant-h6-weight: 500;

  --ant-h1-line-height: 2rem; /* 32px */
  --ant-h2-line-height: 1.875rem; /* 30px */
  --ant-h3-line-height: 1.5rem; /* 24px */
  --ant-h4-line-height: 1.375rem; /* 22px */
  --ant-h5-line-height: 1.25rem; /* 20px */
  --ant-h6-line-height: 1.125rem; /* 18px */

  /* Error and Disabled States */
  --error-base: var(--color-error-base);
  --disabled-text: var(--color-disabled-text);
  --disabled-bg: var(--color-disabled-bg);
  --disabled-border: var(--color-disabled-border);
}

/* Typography Configuration */
.ant-typography {
  font-family: var(--font-family);
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  font-family: var(--ant-font-family);
  font-weight: var(--ant-h1-weight);
}

h1.ant-typography {
  font-size: var(--ant-h1-size);
  line-height: var(--ant-h1-line-height);
}

h2.ant-typography {
  font-size: var(--ant-h2-size);
  line-height: var(--ant-h2-line-height);
}

h3.ant-typography {
  font-size: var(--ant-h3-size);
  line-height: var(--ant-h3-line-height);
}

h4.ant-typography {
  font-size: var(--ant-h4-size);
  line-height: var(--ant-h4-line-height);
}

h5.ant-typography {
  font-size: var(--ant-h5-size);
  line-height: var(--ant-h5-line-height);
}

/* NgZorro Component Overrides */
.ant-btn, .ant-input, .ant-select,
.ant-modal-title, .ant-modal-content,
.ant-dropdown-menu, .ant-menu, .ant-table,
.ant-form, .ant-card {
  font-family: var(--font-family);
}

:host .ant-btn-circle {
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
