.klaro {
  --light1: #000;
  --dark1: #fff;
  @apply text-base leading-5.5;

  .cookie-modal,
  .context-notice,
  .cookie-notice {
    p {
      @apply m-0;
    }
  }

  .cookie-notice:not(.cookie-modal-notice) {
    @apply p-6 md:max-w-md md:rounded-xl shadow-overlay;
    p {
      @apply m-0;
    }
    .cn-body {
      @apply p-0;

      .cn-ok {
        @apply mt-8 flex sm:flex-nowrap gap-x-3 justify-end items-center md:justify-between;

        .cn-learn-more,
        .cn-buttons {
          @apply sm:flex-grow-0 sm:flex-shrink sm:w-48 #{!important};
        }

        .cn-learn-more,
        button.cm-btn {
          @apply flex items-center justify-center leading-5 font-medium normal-case btn no-animation m-0 p-0;
        }

        .cn-buttons {
          @apply m-0;
          button.cm-btn {
            @apply btn-primary mt-2 w-full sm:m-0;
          }
        }

        .cn-learn-more {
          @apply btn-secondary btn-outline border-secondary text-secondary;
        }
      }
    }
  }

  .cookie-modal {
    a {
      @apply underline text-current;
    }
    .cm-bg {
      @apply bg-gray-cold-10 opacity-30;
    }

    .cm-modal {
      .cm-header {
        @apply px-8 pt-16 pb-12;

        button.hide {
          @apply transform scale-150;
        }
        h1.title {
          @apply mb-4;
        }
      }

      .cm-body {
        @apply px-8 py-6;

        .cm-list-label {
          .slider {
            @apply bg-gray-700 shadow-none w-11 h-6 top-1;
            &::before {
              @apply left-0.5 bottom-0.5;
            }
          }
        }
        .cm-list-input:checked + .cm-list-label .slider {
          @apply bg-success-100;
        }

        .cm-list-title {
          @apply text-xs leading-3.5 font-bold;
        }

        .purposes,
        .cm-list-description {
          @apply text-sm font-normal;
        }
      }

      .cm-footer {
        @apply p-8;

        .cm-footer-buttons {
          @apply flex flex-wrap sm:flex-nowrap gap-x-3 justify-between;

          button {
            @apply flex items-center justify-center leading-5 font-medium normal-case btn no-animation w-full m-0 p-0;
            @apply sm:flex-grow-0 sm:flex-shrink sm:w-48 #{!important};

            &.cm-btn-accept {
              @apply btn-secondary btn-outline border-secondary text-secondary border-solid;
            }
            &.cm-btn-accept-all {
              @apply btn-primary mt-2 sm:m-0;
            }
          }
        }

        .cm-powered-by {
          @apply p-0 absolute bottom-0.5 right-8;
        }
      }
    }
  }
}
