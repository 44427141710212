@import './material.scss';
@import './tailwind.scss';
@import './ripple.scss';

@import './variables.scss';

@import "./mixins.scss";

@import './ng-zorro-theme.scss';

@import 'klaro/src/scss/klaro.scss';

@import 'klaro.scss';

@import './core-material.scss';

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
}

html {
  height: -webkit-fill-available;
}
