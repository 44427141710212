@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

@layer base {
  * {
    letter-spacing: 0.5px #{!important};
  }
  h1 {
    @apply text-2.8xl font-bold #{!important};
  }
  h2 {
    @apply text-2xl font-bold #{!important};
  }
  h4 {
    @apply text-lg leading-5.5 font-bold #{!important};
  }
  h5 {
    @apply text-base font-medium #{!important};
  }
}

@layer components {
  .ordered-list-items {
    counter-reset: list-counter; // moved up here

    & li::before {
      content: counter(list-counter);
      counter-increment: list-counter;
      @apply bg-black text-white rounded-full h-6 w-6 mr-3;
      @apply flex items-center justify-center absolute left-0 top-1/2 transform -translate-y-1/2;
    }

    & li {
      @apply relative pl-10 flex items-center;
    }
  }
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 7px;
  border: 3px solid var(--primary);
}
