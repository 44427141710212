/*
  Styling: https://developers.google.com/fonts/docs/material_symbols
  Icons: https://github.com/google/material-design-icons/tree/master/variablefont
*/
@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 100 700;
  src: url(/assets/icons/MaterialSymbolsOutlined[FILL,GRAD,opsz,wght].woff2) format('woff2');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}
