// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$cop-app-typography: mat.m2-define-typography-config(
  // Specify "Inter" as the default font family for all levels.
  $font-family: 'Inter, sans-serif'
);

@include mat.elevation-classes();
@include mat.app-background();

$md-cop-blue: (
  50: #e1e7f5,
  100: #b4c4e5,
  200: #839dd4,
  300: #5175c2,
  400: #2b58b5,
  500: #063aa8,
  600: #0534a0,
  700: #042c97,
  800: #03258d,
  900: #02187d,
  A100: #aab4ff,
  A200: #7787ff,
  A400: #445aff,
  A700: #2a44ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-cop-grey: (
  50: #e9eaeb,
  100: #c8cbcd,
  200: #a4a8ab,
  300: #808589,
  400: #646a70,
  500: #495057,
  600: #42494f,
  700: #394046,
  800: #31373c,
  900: #21272c,
  A100: #76c3ff,
  A200: #43acff,
  A400: #1095ff,
  A700: #0088f6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-cop-error: (
  50: #feeae4,
  100: #fcccbb,
  200: #faaa8e,
  300: #f78761,
  400: #f66e3f,
  500: #f4541d,
  600: #f34d1a,
  700: #f14315,
  800: #ef3a11,
  900: #ec290a,
  A100: #ffffff,
  A200: #ffe5e2,
  A400: #ffb7af,
  A700: #ffa196,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cop-app-primary: mat.m2-define-palette($md-cop-blue);
$cop-app-accent: mat.m2-define-palette($md-cop-grey);

// The warn palette is optional (defaults to red).
$cop-app-warn: mat.m2-define-palette($md-cop-error);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $cop-app-typography: mat.define-typography-config(
//   // Specify "Inter" as the default font family for all levels.
//   $font-family: 'Inter'
// );

$cop-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $cop-app-primary,
      accent: $cop-app-accent,
      warn: $cop-app-warn,
    ),
    typography: $cop-app-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cop-app-theme);
