mat-form-field {
  font-size: 0.8rem !important;
  line-height: 0.8rem !important;
  height: 4rem !important;
  margin-top: 1rem !important;
  color: black !important;
}

.mat-mdc-button-touch-target {
  font-size: 0.8rem !important;
  line-height: 0.8rem !important;
}
